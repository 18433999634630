import { AxiosInstance, AxiosResponse } from "axios";
import qs from "qs";
import { ESignOnClient, PartnerESignType } from "src/types/partner";
import { ApiPath } from "..";
import { ApiResponse } from "../public-types";
import {
  AddPartnerData,
  CtParkinglot,
  EditPartnerData,
  GetPartnerAuthoritiesParams,
  GetPartnersParams,
  Partner,
  PartnerMemberAuthority,
  PartnerStatus,
} from "./type";

/**CPA01. 파트너 목록 페이지 조회 */
export const getPartners = async (
  axios: AxiosInstance,
  params?: GetPartnersParams,
): Promise<AxiosResponse<ApiResponse<{ content: Partner[] }>>> => {
  const _params: any = { ...params };

  if (params?.orders) {
    const _orders = [...params.orders];
    delete _params?.order;

    let index = 0;

    for (const order of _orders) {
      _params[`sort.orders[${index}].direction`] = order.direction;
      _params[`sort.orders[${index}].nullHandling`] = order.nullHandling;
      _params[`sort.orders[${index}].property`] = order.property;
      _params[`sort.orders[${index}].ignoreCase`] = order.ignoreCase;

      index++;
    }
  }

  return await axios.get(ApiPath.partner, {
    params: _params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  });
};

/**CPA01. 파트너 목록 페이지 조회 */
export const getPartnerList = async (
  axios: AxiosInstance,
  params?: GetPartnersParams,
): Promise<AxiosResponse<ApiResponse<{ content: Partner[] }>>> => {
  const _params: any = { ...params };

  if (params?.orders) {
    const _orders = [...params.orders];
    delete _params?.order;

    let index = 0;

    for (const order of _orders) {
      _params[`sort.orders[${index}].direction`] = order.direction;
      _params[`sort.orders[${index}].nullHandling`] = order.nullHandling;
      _params[`sort.orders[${index}].property`] = order.property;
      _params[`sort.orders[${index}].ignoreCase`] = order.ignoreCase;

      index++;
    }
  }

  return await axios.get(ApiPath.partner, {
    params: _params,
    paramsSerializer: (params) => qs.stringify(params, { encode: true }),
  });
};

// CPA02. 파트너 생성 처리
export const postPartner = async (
  axios: AxiosInstance,
  data?: AddPartnerData,
): Promise<AxiosResponse<ApiResponse<{ content: Array<Partner> }>>> => {
  return await axios.post(ApiPath.partner, data);
};

// CPA03. 파트너 조회
export const getPartner = async (
  axios: AxiosInstance,
  params?: {
    id: number | string;
  },
): Promise<AxiosResponse<ApiResponse<{ partner: Partner }>>> => {
  return await axios.get(ApiPath.partner + `/${params?.id}`);
};

const getAPiPathPatchPartner = (id: number | string) => `${ApiPath.partner}/${id}`;

// CPA04. 파트너 수정 처리
export const putPartner = async (
  axios: AxiosInstance,
  params?: {
    id: number | string;
    data?: EditPartnerData;
  },
): Promise<AxiosResponse<ApiResponse<{ partner: Partner }>>> => {
  if (params?.id === undefined) throw new Error("id is undefined");

  return await axios.put(getAPiPathPatchPartner(params.id), params?.data);
};

const getApiPathPatchPartnerStatus = (id: number | string) => `${ApiPath.partner}/${id}/status`;

// CPA05. 파트너 상태 수정 처리
export const patchPartnerStatus = async (
  axios: AxiosInstance,
  params?: {
    id: number | string;
    status?: PartnerStatus;
  },
): Promise<AxiosResponse<ApiResponse<{ partner: Partner }>>> => {
  if (params?.id === undefined) throw new Error("id is undefined");

  return await axios.patch(getApiPathPatchPartnerStatus(params.id), {
    status: params?.status,
  });
};

const getApiPathPatchPartnerESignLink = (id: number | string) =>
  `${ApiPath.partner}/${id}/electronicSignatureIntegration`;

// CPA06. 파트너 상태 수정 처리
export const patchPartnerESignLink = async (
  axios: AxiosInstance,
  params?: {
    id: number | string;
    type?: PartnerESignType;
  },
): Promise<AxiosResponse<ApiResponse<{ partner: Partner }>>> => {
  if (params?.id === undefined) throw new Error("id is undefined");

  return await axios.patch(getApiPathPatchPartnerESignLink(params.id), {
    electronicSignatureIntegrationType: params?.type,
  });
};

const getApiPathPartnerESignClient = (id: number | string) =>
  `/api/central/ct/esign/partners/${id}`;

export const getPartnerESignClient = async (
  axios: AxiosInstance,
  params?: {
    id: number | string;
  },
): Promise<AxiosResponse<ApiResponse<{ client: ESignOnClient }>>> => {
  return await axios.get(getApiPathPartnerESignClient(params!.id));
};

const checkApiPathPartnerESignClient = (partnerId: number | string) =>
  `/api/central/ct/esign/partners/${partnerId}/check-esignon`;

export const checkPartnerESignClient = async (
  axios: AxiosInstance,
  params?: {
    partnerId: number | string;
    eSignonClient: ESignOnClient;
  },
): Promise<AxiosResponse<ApiResponse<{ client: ESignOnClient }>>> => {
  return await axios.post(checkApiPathPartnerESignClient(params!.partnerId), {
    eSignonClient: params?.eSignonClient,
  });
};

const postApiPathPartnerESignClient = (id: number | string) =>
  `/api/central/ct/esign/partners/${id}`;

export const postPartnerESignClient = async (
  axios: AxiosInstance,
  params?: {
    id: number | string;
    eSignonClient: ESignOnClient;
  },
): Promise<AxiosResponse<ApiResponse<{ client: ESignOnClient }>>> => {
  return await axios.post(postApiPathPartnerESignClient(params!.id), {
    eSignonClient: params!.eSignonClient,
  });
};

export const patchPartnerESignClient = async (
  axios: AxiosInstance,
  params?: {
    id: number | string;
    eSignonClient: ESignOnClient;
  },
): Promise<AxiosResponse<ApiResponse<{ client: ESignOnClient }>>> => {
  return await axios.patch(postApiPathPartnerESignClient(params!.id), {
    eSignonClient: params!.eSignonClient,
  });
};

// CPA07. 파트너 feature 활성 여부 저장 처리
export const patchPartnerFeatureActiveAsync = async (
  axios: AxiosInstance,
  params?: {
    partnerId?: string;
    featureName?: string;
    active?: boolean;
  },
): Promise<AxiosResponse<ApiResponse<{ partner: Partner }>>> => {
  if (!params?.partnerId) throw new Error("partnerId is undefined");
  if (!params?.featureName) throw new Error("featureName is undefined");

  const path = `/api/central/pa/partners/${params.partnerId}/features/${params.featureName}`;
  return await axios.patch(path, {
    active: params.active,
  });
};

// CPA21. 파트너 회원 권한 목록 페이지 조회
export const getPartnerAuthoritiesAsync = async (
  axios: AxiosInstance,
  params?: GetPartnerAuthoritiesParams,
): Promise<AxiosResponse<ApiResponse<{ content: PartnerMemberAuthority[] }>>> => {
  if (!params) throw Error("params not found");

  const { partnerId, ...payload } = params;
  const path = `/api/central/pa/partners/${partnerId}/member/authorities`;
  return await axios.get(path, {
    params: payload,
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};

// CPA22. 파트너 회원 readonly 권한 제한 수정 처리
export const patchPartnerReadonlyAsync = async (
  axios: AxiosInstance,
  params?: {
    partnerId: string;
    memberId: string;
    readOnly: boolean;
  },
): Promise<AxiosResponse<ApiResponse<{ member: PartnerMemberAuthority }>>> => {
  if (!params?.partnerId) throw new Error("partnerId is undefined");
  if (!params?.memberId) throw new Error("memberId is undefined");

  const path = `/api/central/pa/partners/${params.partnerId}/members/${params.memberId}/read-only`;
  return await axios.patch(path, {
    readOnly: params.readOnly,
  });
};
// CPA23. 파트너 회원 권한 수정 처리
export const putPartnerPrivilegeAsync = async (
  axios: AxiosInstance,
  params?: {
    partnerId: string;
    memberId: string;
    privilege: string;
    permissions: string[];
  },
): Promise<AxiosResponse<ApiResponse<{ member: PartnerMemberAuthority }>>> => {
  if (!params?.partnerId) throw new Error("partnerId is undefined");
  if (!params?.memberId) throw new Error("memberId is undefined");

  const path = `/api/central/pa/partners/${params.partnerId}/members/${params.memberId}/privileges/${params.privilege}/permissions`;
  return await axios.put(path, {
    permissions: params.permissions,
  });
};

// CCK01. 주차장 정보 등록 (KPS)
export const postParkingLotAsync = async (
  axios: AxiosInstance,
  params?: { partnerId: string } & CtParkinglot,
): Promise<AxiosResponse<ApiResponse<{ partnerId: string }>>> => {
  if (!params) throw Error("params not found");
  const { partnerId, ...parkinglot } = params;
  const path = `/api/central/ct/parking/partners/${params.partnerId}/parkinglot`;
  return await axios.post(path, {
    parkinglot,
  });
};

// CCK02. 주차장 정보 조회 (KPS)
export const getParkingLotAsync = async (
  axios: AxiosInstance,
  params?: { partnerId: string },
): Promise<AxiosResponse<ApiResponse<{ parkinglot: CtParkinglot }>>> => {
  if (!params) throw Error("params not found");

  const path = `/api/central/ct/parking/partners/${params.partnerId}/parkinglot`;
  return await axios.get(path, {
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true, encode: true }),
  });
};
