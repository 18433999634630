import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useApiOperation } from "src/api/hooks";
import { Order, PageMeta } from "src/api/public-types";
import { BaseSelect, BaseTable } from "src/components";
import { useLoadingBarContext } from "src/pages/hooks";

import {
  getMessageEmailListAsync,
  getMessageKakaotalkListAsync,
  getMessageSMSListAsync,
} from "src/api/message/message-api";

import {
  MessageEmailDetail,
  MessageKakaotalkDetail,
  MessageSMSDetail,
} from "src/api/message/message-types";
import { messageListColumns } from "./columns";

// 공개 여부
const messageTypes = [
  { value: "SMS", label: "SMS" },
  { value: "kakaotalk", label: "카카오톡" },
  { value: "email", label: "이메일" },
];

type messageType = "SMS" | "kakaotalk" | "email";

// 정렬기능 숨김
const disabledSortHeaders: string[] = [
  "usageNo",
  "createdDate",
  "receiverPhoneNumber",
  "subject",
  "template",
  "service",
  "status",
  "content",
  "type",
  "altSendType",
  "from",
  "to",
  "cc",
  "bcc",
  "subject",
];

// url query parameter 타입
type QueryParams = {
  messageType?: messageType;
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
};

/*
  공지사항 목록 화면
*/
const MessageList = () => {
  // 로딩바
  const { setLoadingBar } = useLoadingBarContext();

  const navigate = useNavigate();
  const location = useLocation();

  // location search (url query parameter) 를 읽어서 object 로 변환
  const queryParams: QueryParams = useMemo(() => {
    const _queryParams: QueryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      allowDots: true,
    });
    if (!_queryParams?.messageType) {
      _queryParams.messageType = "SMS"; // 기본 선택 옵션
    }
    // page, size, sort 없이 최초 진입했을때 default 값 바인딩
    if (!_queryParams?.page) {
      _queryParams.page = 0;
    }
    if (!_queryParams?.size) {
      _queryParams.size = 20;
    }
    if (!_queryParams?.sort) {
      _queryParams.sort = {
        orders: [{ property: "id", direction: "DESC" }],
      };
    }

    return _queryParams;
  }, [location]);

  // 공지사항 목록 조회
  const [messageList, setMessageList] = useState<
    MessageSMSDetail[] | MessageKakaotalkDetail[] | MessageEmailDetail[]
  >([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const { executeAsync: getMessageSMSList } = useApiOperation(getMessageSMSListAsync);
  const { executeAsync: getMessageKakaotalkList } = useApiOperation(getMessageKakaotalkListAsync);

  const { executeAsync: getMessageEmailList } = useApiOperation(getMessageEmailListAsync);

  const getMessageList = {
    SMS: getMessageSMSList,
    kakaotalk: getMessageKakaotalkList,
    email: getMessageEmailList,
  } as const;

  useEffect(() => {
    const fetchApi = async (messageParam: QueryParams) => {
      setLoadingBar(true);
      // 공지사항 목록 조회
      await getMessageList[messageParam.messageType!](messageParam).then((res) => {
        console.log(messageParam.messageType, "====", res?.data?.data?.content);
        setMessageList(res?.data?.data?.content ?? []);
        setPageMeta(res?.data?.meta.pageMeta);
      });
      setLoadingBar(false);
    };

    fetchApi(queryParams);
  }, [queryParams]);

  // 받아온 query parameter 로 해당 컴포넌트에서 변경가능하기 위한 state 선언
  const [params, setParams] = useState<QueryParams>({ ...queryParams });

  const navigateWithQueryParams = useCallback(
    (passParams?: QueryParams, type?: "search" | "pagination") => {
      let data;
      if (type) {
        type === "search" ? (data = { ...params }) : (data = { ...queryParams });
      }

      const newQueryParams = { ...data, ...(passParams || {}) };
      const newQueryParamStr = qs.stringify(newQueryParams, { allowDots: true });
      navigate(location.pathname + "?" + decodeURIComponent(newQueryParamStr));
    },
    [navigate, location.pathname, params, queryParams],
  );

  return (
    <div className="page-product-list">
      <div className="contents-container__search-wrap">
        <div className="left-area">
          <div className="minmax140 mr8">
            <BaseSelect
              placeholder="메시지 선택"
              value={params?.messageType}
              stateOptions={messageTypes}
              setStateValue={(messageType: messageType) => navigateWithQueryParams({ messageType })}
            />
          </div>
        </div>
      </div>

      <BaseTable
        data={messageList}
        columns={messageListColumns[params?.messageType!] ?? []}
        pageIndex={Number(params?.page || 0)}
        totalPages={pageMeta?.totalPages || 0}
        goPage={(page: number) => {
          navigateWithQueryParams({ page }, "pagination");
        }}
        orders={params?.sort?.orders}
        disabledSortHeaders={disabledSortHeaders}
        setOrders={(orders?: Array<Order>) => {
          if (orders) {
            navigateWithQueryParams({ sort: { orders } }, "search");
          }
        }}
      />
    </div>
  );
};

export default MessageList;
