import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Cell } from "react-table";
import { ContentsTitle, BaseButton, LoadingBar } from "src/components";
import ViewDataTable from "src/pages/building/components/ViewDataTable";
import { useTitleOperation } from "src/pages/hooks";
import { Partner } from "src/types/partner";
import { IPartnerMember } from "src/types/partner-members";
import { YmdFormat } from "src/utils";
import usePartnerMember from "../hooks/usePartnerMember";
import {
  getPartnerMemberDetailPagePath,
  getPartnerMemberEditPath,
  getPartnerMemberListPagePath,
} from "../utils";
import { PagePath } from "src/pages/product/details";
import BaseGaryTooltip from "src/components/BaseGaryTooltip";
import { useEffect } from "react";
import { PaPartnerMemberPrivileges } from "src/api/partner-member/types";

const PARTNER_COLUMNS = [
  {
    Header: "id",
    accessor: "id",
    width: 70,
    Cell: ({ value, row }: Cell<Partner>) => {
      return (
        <div>
          <Link
            target={"_blank"}
            to={`${PagePath.partner.detail.replace(
              ":id",
              value,
            )}?tab=authorities&page=0&size=20&containsEmail=${row.original.email}`}
            className="text-hilight"
          >
            {value}
          </Link>
        </div>
      );
    },
  },
  {
    Header: "파트너 코드",
    accessor: "code",
    width: 300,
    Cell: ({ value }: Cell<Partner>) => <p>{value}</p>,
  },
  {
    Header: "파트너 이름",
    accessor: "name",
    width: 300,
    Cell: ({ value }: Cell<Partner>) => <p>{value}</p>,
  },
  {
    Header: "권한",
    accessor: "privileges",
    width: 100,
    Cell: ({ value }: Cell<Partner>) => {
      console.log(value);
      const renderPrivileges = (privileges: { code: string; permissions: string }[]) => {
        return (
          <>
            {privileges ? (
              privileges?.map((item) => (
                <p key={item.code}>
                  {item.code} : {item.permissions ? String(item.permissions).toUpperCase() : ""}
                </p>
              ))
            ) : (
              <>권한이 없습니다</>
            )}
          </>
        );
      };
      return (
        <div>
          <BaseGaryTooltip touchIcon="INFO" children={<>{renderPrivileges(value)}</>} />
        </div>
      );
    },
  },
];

const DETAIL_MAP = {
  id: {
    label: "id",
    render: (value: IPartnerMember) => <p>{value.id}</p>,
    width: 400,
  },
  email: {
    label: "이메일",
    render: (value: IPartnerMember) => <p>{value.email}</p>,
    width: 400,
  },
  connections: {
    label: "연결된 계정",
    render: (values: IPartnerMember) => <p>{values.connections?.join(", ")}</p>,
    width: 400,
  },
  partners: {
    label: "소속 파트너",
    render: (values: IPartnerMember, privileges: PaPartnerMemberPrivileges[]) => {
      const _partners = values.partners?.map((partner) => {
        const findPrivileges = privileges?.find((item) => Number(item.partnerId === partner.id));
        return {
          id: partner.id,
          code: partner.code,
          name: partner.name,
          email: values.email,
          privileges: findPrivileges?.privileges,
        };
      });
      return <ViewDataTable columns={PARTNER_COLUMNS} data={_partners || []} />;
    },
    width: 1000,
  },
  createdDate: {
    label: "생성일",
    render: (value: IPartnerMember) => <p>{moment(value.createdDate).format(YmdFormat.FULL)}</p>,
    width: 300,
  },
  lastLoginDate: {
    label: "최근 로그인",
    render: (value: IPartnerMember) => <p>{moment(value.lastLoginDate).format(YmdFormat.FULL)}</p>,
    width: 300,
  },
};

function BasicInfo() {
  const params = useParams();
  const { partnerMember, loading, privileges } = usePartnerMember(params.id!);

  const navigate = useNavigate();

  const handleListButtonClick = () => navigate(getPartnerMemberListPagePath());
  const handleEditButtonClick = () => navigate(getPartnerMemberEditPath(params.id!));
  useTitleOperation(partnerMember?.email);

  if (loading) return <LoadingBar visible={true} />;

  return (
    <div>
      <div className="contents-container__scroll">
        <div className="contents-container__wrap">
          <div className="contents-container__wrap-contents pt30">
            {partnerMember &&
              Object.keys(DETAIL_MAP).map((key, index) => {
                const detail = DETAIL_MAP[key as keyof typeof DETAIL_MAP];
                return (
                  <section className="contents-container__grid" key={key}>
                    <div className="contents-container__grid-index">
                      <p>{detail.label}</p>
                    </div>
                    <div className="contents-container__grid-contents">
                      <div className={`minmax${detail.width}`}>
                        {detail.render(partnerMember, privileges)}
                      </div>
                    </div>
                  </section>
                );
              })}
          </div>
          <div className="contents-container__btn-wrap">
            <div className="left-area">
              <BaseButton
                title={"목록"}
                onClick={handleListButtonClick}
                className="color-white size-large"
              />
            </div>
            <div className="right-area">
              <BaseButton title={"수정"} onClick={handleEditButtonClick} className="size-large" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BasicInfo;
