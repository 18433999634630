import { Link } from "react-router-dom";
import { PagePath } from "src/pages/product/details";

export interface AuditLogDto {
  path: string;
  category: string;
  message: {
    type: string;
    data: any;
  };
  user: AuditLogUser;
  revisionTime: string;
}

export interface AuditLogUser {
  id: string;
  type: string;
  displayName: string;
}

export const LogCategoryType = {
  PARTNER: "PARTNER", //파트너
  PARTNER_MEMBER: "PARTNER_MEMBER", //파트너회원
  ACCESS_GROUP: "ACCESS_GROUP", //출입그룹
  IOT: "IOT",
} as const;

export const categorizeLogs: { [key: string]: string } = {
  AL001: LogCategoryType.PARTNER,
  AL002: LogCategoryType.PARTNER,
  AL003: LogCategoryType.PARTNER,
  AL004: LogCategoryType.PARTNER,
  AL005: LogCategoryType.PARTNER_MEMBER,
  AL006: LogCategoryType.ACCESS_GROUP,
  AL007: LogCategoryType.ACCESS_GROUP,
  AL008: LogCategoryType.IOT,
  AL009: LogCategoryType.IOT,
  AL010: LogCategoryType.IOT,
};

export const logPrintName: { [key: string]: string } = {
  AL001: "파트너/feature",
  AL002: "파트너/권한",
  AL003: "파트너/전자서명",
  AL004: "파트너/주차 연동",
  AL005: "파트너 회원/파트너",
  AL006: "출입그룹/파트너",
  AL007: "출입그룹/장치",
  AL008: "IoT/디바이스",
  AL009: "IoT/디바이스/제어",
  AL010: "IoT/디바이스/파트너",
};

export const renderLogMessage = (messageType: string, data: any, setLabelModal?: Function) => {
  switch (messageType) {
    case "AL001-U01":
      return (
        <p>
          {`${data.partnerFeature?.name} 기능이`}{" "}
          <b>{`${data.partnerFeature?.active ? "활성" : "비활성"}`} </b>
          {`되었습니다.`}
        </p>
      );
    case "AL002-U01":
      return (
        <p>
          {`${data.partnerMember?.email}`}
          <Link
            target="_blank"
            to={PagePath.partnerMember.detail.replace(":id", data.partnerMember?.id)}
          >{`(${data.partnerMember?.id})`}</Link>{" "}
          {`사용자의 읽기 전용 권한 제한을 해제 합니다.`}
        </p>
      );

    case "AL002-U02":
      return (
        <p>
          {`${data.partnerMember?.email}`}
          <Link
            target="_blank"
            to={PagePath.partnerMember.detail.replace(":id", data.partnerMember?.id)}
          >{`(${data.partnerMember?.id})`}</Link>{" "}
          {`사용자를 읽기 전용 권한으로 제한 합니다.`}
        </p>
      );
    case "AL003-C01":
      return <p>{`이싸인온 로그인 정보(${data.esignOnLogin?.email})가 등록 되었습니다.`}</p>;
    case "AL003-U01":
      return (
        <p>
          {`이싸인온 로그인 이메일이 `}
          <b>{`${data.esignOnLogin?.email}`} </b>
          {`이메일로 변경 되었습니다.`}
        </p>
      );
    case "AL003-U02":
      return <p>{`이싸인온 로그인 패스워드가 변경 되었습니다.`}</p>;
    case "AL003-D01":
      return <p>{`이싸인온 로그인 정보(${data.esignOnLogin?.email})가 삭제 되었습니다.`}</p>;
    case "AL004-U01":
      return (
        <p>
          {`주차 연동 주차장 키가 `}
          <b>{`${data.parkinglotKey}`}</b>
          {` 값으로 등록 되었습니다.`}
        </p>
      );
    case "AL005-A01":
      return (
        <p>
          {`${data.partner?.name}`}
          <Link
            target="_blank"
            to={PagePath.partner.detail.replace(":id", data.partner?.id)}
          >{`(${data.partner?.code})`}</Link>
          {` 파트너 회원으로 초대 되었습니다.`}
        </p>
      );
    case "AL005-R01":
      return (
        <p>
          {`${data.partner?.name}`}
          <Link
            target="_blank"
            to={PagePath.partner.detail.replace(":id", data.partner?.id)}
          >{`(${data.partner?.code})`}</Link>
          {` 파트너 회원 자격이 삭제 되었습니다.`}
        </p>
      );
    case "AL006-A01":
      return (
        <p>
          {`${data.partner?.name}`}
          <Link
            target="_blank"
            to={PagePath.partner.detail.replace(":id", data.partner?.id)}
          >{`(${data.partner?.code})`}</Link>
          {` 파트너가 추가 되었습니다.`}
        </p>
      );
    case "AL006-R01":
      return (
        <p>
          {`${data.partner?.name}`}
          <Link
            target="_blank"
            to={PagePath.partner.detail.replace(":id", data.partner?.id)}
          >{`(${data.partner?.code})`}</Link>
          {` 파트너가 삭제 되었습니다.`}
        </p>
      );
    case "AL007-A01":
      return (
        <p>
          {`${data.accessDevice?.deviceName}`}
          <Link
            target="_blank"
            to={PagePath.accessDevice.detail.replace(":id", data.accessDevice?.id)}
          >{`(${data.accessDevice?.externalDeviceId})`}</Link>
          {` 장치가 추가 되었습니다.`}
        </p>
      );
    case "AL007-R01":
      return (
        <p>
          {`${data.accessDevice?.deviceName}`}
          <Link
            target="_blank"
            to={PagePath.accessDevice.detail.replace(":id", data.accessDevice?.id)}
          >{`(${data.accessDevice?.externalDeviceId})`}</Link>
          {` 장치가 삭제 되었습니다.`}
        </p>
      );
    case "AL008-U01":
      return (
        <p>
          {`기기명이 `}
          <b>{`${data.registeredDevice?.internalDisplayName}`}</b>
          {` 이름으로 변경 되었습니다.`}
        </p>
      );
    case "AL008-U02":
      return (
        <>
          <p>
            {`${data.registeredDevice?.internalDisplayName}`}
            {` 기기의 라벨 정보가 `}
            <span
              className="text-underline cursor-pointer"
              onClick={() => {
                setLabelModal ? setLabelModal({ isOpen: true }) : console.log("레이어 팝업 출력");
              }}
            >
              변경
            </span>
            되었습니다.
          </p>
        </>
      );
    case "AL009-C01":
      return (
        <>
          <p>
            {`${data.control?.displayName}`}
            {`(${data.control?.id})`}
            {` 제어가 등록 되었습니다.`}
          </p>
        </>
      );
    case "AL009-U01":
      return (
        <>
          <p>
            {`제어(${data.control?.id})의 제어명이 `}
            <b>{`${data.control?.displayName}`}</b>
            {` 이름으로 변경 되었습니다.`}
          </p>
        </>
      );

    case "AL009-U02":
      return (
        <>
          <p>
            {`${data.control?.displayName}`}
            {`(${data.control?.id}) 제어의 라벨 정보가 `}
            <span
              className="text-underline cursor-pointer"
              onClick={() => {
                setLabelModal ? setLabelModal({ isOpen: true }) : console.log("레이어 팝업 출력");
              }}
            >
              변경
            </span>
            되었습니다.
          </p>
        </>
      );
    case "AL009-D01":
      return (
        <>
          <p>
            {`${data.control?.displayName}`}
            {`(${data.control?.id})`}
            {` 제어가 삭제 되었습니다.`}
          </p>
        </>
      );
    case "AL010-A01":
      return (
        <p>
          {`${data.partner?.name}`}
          <Link
            target="_blank"
            to={PagePath.partner.detail.replace(":id", data.partner?.id)}
          >{`(${data.partner?.code})`}</Link>
          {` 파트너가 추가 되었습니다.`}
        </p>
      );
    case "AL010-R01":
      return (
        <p>
          {`${data.partner?.name}`}
          <Link
            target="_blank"
            to={PagePath.partner.detail.replace(":id", data.partner?.id)}
          >{`(${data.partner?.code})`}</Link>
          {` 파트너가 삭제 되었습니다.`}
        </p>
      );
    default:
      return <>정의되지 않음</>;
  }
};
