import { Partner } from "src/types/partner";

type SearchTarget = keyof Pick<Partner, "id" | "name">;

type PageType = "list" | "add" | "edit" | "detail";

export type { PageType, SearchTarget };

export const featureNameToText = (name: string) => {
  switch (name) {
    case "CTRLROOM_NAVIGATION_MAINTENANCE_FEE":
      return "Ctrl.room 에 관리비 관련 메뉴를 제공합니다.";
    case "CTRLROOM_NAVIGATION_RESERVATION":
      return "Ctrl.room 에 공용공간 예약관련 메뉴를 제공합니다.";
    case "CTRLROOM_NAVIGATION_CS":
      return "Ctrl.room 에 CS 메뉴를 제공합니다.";
    case "CTRLROOM_NAVIGATION_WORKORDER":
      return "Ctrl.room 에서 워크오더 메뉴를 제공합니다.";
    case "CTRLROOM_PRODUCT_S2":
      return "Ctrl.room 에서 공간예약 상품의 생성을 제공합니다.";
    case "FEATURE_TAAP_VOC":
      return `Taap의 [도와주세요] 버튼 클릭시 불편신고 기능으로 연결합니다.\n*비활성화시 [상품 > 이용안내 > URL] 에 입력된 링크로 연결됩니다.`;
    case "FEATURE_TAAP_VOC_BUTTON":
      return "Taap의 [도와주세요] 버튼을 노출합니다.";
    case "FEATURE_RESERVATION_POST_PAYMENT":
      return "Ctrl.room의 공용공간 예약 메뉴에 후불정산 기능을 제공합니다.";
    case "FEATURE_TAAP_ORDER_ROBOT":
      return "Taap 에서 F&B 로봇 주문 기능을 제공하는지의 여부";
    case "FEATURE_AC1_QR":
      return "Taap 회원에게 노출하는 출입QR을 AC1(숫자16자리) 타입으로 구성하는지의 여부";
    case "CTRLROOM_PRODUCT_PUBLIC":
      return "Ctrl.room에서 상품의 공개여부를 변경할수 있게 합니다.";
    case "FEATURE_PARKING_KPS":
      return "Taap/Ctrl.room 에서 KPS연동에 의한 주차 사전할인 및 정기권 등록 기능을 활성화합니다.";
    case "FEATURE_TAAP_CAR_RENTAL_TURU":
      return "Taap에서 Turu Car 앱을 통한 차량대여 서비스를 제공합니다.";
    case "FEATURE_ACCESS_LOG_UNMASKED":
      return "Ctrlroom/Taapspace 에서 출입기록 조회시 마스킹되지 않은 데이터가 출력되게 합니다.";
    case "FEATURE_TAAP_QUICK_DELIVERY_EARLN":
      return "Taap에서 earln을 통한 퀵/택배 서비스를 제공합니다.";
    default:
      return "-";
  }
};
