import { string } from "prop-types";
import { Order } from "../public-types";

/*
  메시지 발송 목록 조회 parameter 
 */
export interface MessageListParams {
  page?: number;
  size?: number;
  sort?: {
    orders?: Array<Order>;
  };
}

export interface EmailAddress {
  email?: string;
  name?: string;
}

export type EcService = "POPBILL" | "AWS" | "GOOGLE" | "MS";

export type EcMessageUsageType = "SMS" | "LMS" | "XMS" | "MMS"; // 단문 , 장문 , 단문/장문 , 포토

export const EcMessageStatusCodes = {
  UNKNOWN: "알 수 없음",
  PENDING: "메시지 전송 결과 대기 중",
  SUCCESS: "메시지 전송 성공",
  FAILED: "메시지 전송 실패",
  PARTIAL_SUCCESS: "메시지 전송 일부 성공",
  CANCELLED: "메시지 전송 취소",
};

export type EcMessageStatusCode = keyof typeof EcMessageStatusCodes;

export interface EcMessageStatus {
  code: EcMessageStatusCode;
  time: string;
}

// SMS 메시지 타입
export interface MessageSMSDetail {
  id?: string | number; // 아이디
  usageNo?: string; // 메시지 발송 관리 번호
  service?: EcService; // 메시지 서비스 타입
  receiptNo?: string; // 접수 번호
  type?: EcMessageUsageType; // 메시지 타입
  subject?: string; // 메시지 제목 (선택 사항)
  template?: string; // 메시지 내용에 사용 된 템플릿
  senderPhoneNumber?: string; // 발신 번호
  senderName?: string; // 발신자 이름 (선택 사항)
  receiverPhoneNumber?: string; // 수신자 번호
  createdDate?: string; // 발송 내역 생성 시간
  status?: EcMessageStatus; // 전송 상태
}

// SMS 메시지 타입 리스폰스 타입
export interface getMessageSMSListResponseData {
  content: MessageSMSDetail[];
}

// kakao 메시지 타입
export interface MessageKakaotalkDetail {
  id?: string | number; // 아이디
  usageNo?: string; // 메시지 발송 관리 번호
  service?: EcService; // 메시지 서비스 타입
  receiptNo?: string; // 접수 번호
  type?: EcMessageUsageType; // 메시지 타입
  templateCode?: string; //템플릿 코드 022070000172
  content?: string; //		메시지 내용
  template?: string; // 메시지 내용에 사용 된 템플릿 kakaotalk/kCT001.r6
  senderPhoneNumber?: string; // 발신 번호
  receiverPhoneNumber?: string; // 수신자 번호
  altSendType?: string; //대체 메시지 전송 타입 EcKakaoTalkUsage.Alt.SendType 타입 참고
  createdDate?: string; // 발송 내역 생성 시간 2024-06-07T15:42:00Z
  status?: EcMessageStatus; // 전송 상태 전송 상태 EcMessageStatus 타입 참고
}

// 이메일 메시지 타입 리스폰스 타입
export interface getMessageKakaotalkListResponseData {
  content: MessageKakaotalkDetail[];
}
// 이메일 메시지 타입
export interface MessageEmailDetail {
  id?: string | number; // 아이디
  usageNo?: string; // 메시지 발송 관리 번호
  service?: EcService; // 메시지 서비스 타입

  from?: EmailAddress; //보내는 사람 이메일
  replyTo?: EmailAddress; //답장 받을 이메일
  to?: EmailAddress[]; //받는 사람 이메일 목록
  cc?: EmailAddress[]; //참조 이메일 목록
  bcc?: EmailAddress[]; //숨은 참조 이메일 목록

  subject?: string; // 메시지 제목 (선택 사항)
  template?: string; // 메시지 내용에 사용 된 템플릿
  createdDate?: string; // 발송 내역 생성 시간
  status?: EcMessageStatus; // 전송 상태
}

// kakao 메시지 타입 리스폰스 타입
export interface getMessageEmailListResponseData {
  content: MessageEmailDetail[];
}
