import { Link, useParams } from "react-router-dom";
import { Cell } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { patchPartnerReadonlyAsync } from "src/api/partner";
import { deletePartnerMemberAsync } from "src/api/partner-member/api";
import { PartnerMemberAuthority } from "src/api/partner/type";
import { useLoadingBarContext } from "src/pages/hooks";
import { PagePath } from "src/pages/product/details";
import { PrivilegeCode } from "src/types/partner";
import Permissions from "./Permissions";

const columns: any = [
  {
    Header: "id",
    accessor: "memberId",
    width: 300,
    Cell: (props: Cell<PartnerMemberAuthority>) => {
      const detailPath = `${PagePath.partnerMember.detail.replace(
        ":id",
        `${props.row.original.memberId}`,
      )}`;
      return (
        <>
          <Link to={detailPath} target="_blank" className="text-left w-100 text-hilight">
            {props.value}
          </Link>
        </>
      );
    },
  },
  {
    Header: "이메일",
    accessor: "email",
    width: 240,
    Cell: (props: Cell<PartnerMemberAuthority>) => {
      return <p className="text-left w-100">{props.value}</p>;
    },
  },
  {
    Header: "상태",
    accessor: "blocked",
    width: 100,
    Cell: (props: Cell<PartnerMemberAuthority>) => {
      return (
        <div className={props.value ? "chip red" : "chip green"}>
          {props.value ? "비활성" : "활성"}
        </div>
      );
    },
  },
  {
    Header: "readonly",
    accessor: "readOnly",
    width: 100,
    Cell: ({ row, value, setPartnerAuthList }: any) => {
      return (
        <>
          {value === true
            ? value.toString().toUpperCase()
            : value === false
            ? value.toString().toUpperCase()
            : "-"}
        </>
      );
    },
  },
  {
    Header: "BASIC",
    accessor: "BASIC",
    width: 100,
    Cell: ({ row, value, fetchApi }: any) => {
      const { id: partnerId } = useParams();
      const memberId = row.original.memberId;
      const privileges = row.original.privileges;

      return (
        <Permissions
          code={PrivilegeCode.BASIC}
          partnerId={partnerId}
          memberId={memberId}
          privileges={privileges}
          fetchApi={fetchApi}
        />
      );
    },
  },
  {
    Header: "BUILDING",
    accessor: "BUILDING",
    width: 100,
    Cell: ({ row, value, fetchApi }: any) => {
      const { id: partnerId } = useParams();
      const memberId = row.original.memberId;
      const privileges = row.original.privileges;

      return (
        <Permissions
          code={PrivilegeCode.BUILDING}
          partnerId={partnerId}
          memberId={memberId}
          privileges={privileges}
          fetchApi={fetchApi}
        />
      );
    },
  },
  {
    Header: "PRODUCT",
    accessor: "PRODUCT",
    width: 100,
    Cell: ({ row, value, fetchApi }: any) => {
      const { id: partnerId } = useParams();
      const memberId = row.original.memberId;
      const privileges = row.original.privileges;

      return (
        <Permissions
          code={PrivilegeCode.PRODUCT}
          partnerId={partnerId}
          memberId={memberId}
          privileges={privileges}
          fetchApi={fetchApi}
        />
      );
    },
  },
  {
    Header: "WORKORDER",
    accessor: "WORKORDER",
    width: 110,
    Cell: ({ row, value, fetchApi }: any) => {
      const { id: partnerId } = useParams();
      const memberId = row.original.memberId;
      const privileges = row.original.privileges;

      return (
        <Permissions
          code={PrivilegeCode.WORKORDER}
          partnerId={partnerId}
          memberId={memberId}
          privileges={privileges}
          fetchApi={fetchApi}
        />
      );
    },
  },
  {
    Header: "CS",
    accessor: "CS",
    width: 100,
    Cell: ({ row, value, fetchApi }: any) => {
      const { id: partnerId } = useParams();
      const memberId = row.original.memberId;
      const privileges = row.original.privileges;

      return (
        <Permissions
          code={PrivilegeCode.CS}
          partnerId={partnerId}
          memberId={memberId}
          privileges={privileges}
          fetchApi={fetchApi}
        />
      );
    },
  },
  {
    Header: "CONTRACT",
    accessor: "CONTRACT",
    width: 100,
    Cell: ({ row, value, fetchApi }: any) => {
      const { id: partnerId } = useParams();
      const memberId = row.original.memberId;
      const privileges = row.original.privileges;

      return (
        <Permissions
          code={PrivilegeCode.CONTRACT}
          partnerId={partnerId}
          memberId={memberId}
          privileges={privileges}
          fetchApi={fetchApi}
        />
      );
    },
  },
  {
    Header: "RESERVATION",
    accessor: "RESERVATION",
    width: 110,
    Cell: ({ row, value, fetchApi }: any) => {
      const { id: partnerId } = useParams();
      const memberId = row.original.memberId;
      const privileges = row.original.privileges;

      return (
        <Permissions
          code={PrivilegeCode.RESERVATION}
          partnerId={partnerId}
          memberId={memberId}
          privileges={privileges}
          fetchApi={fetchApi}
        />
      );
    },
  },
  {
    Header: "VISITOR",
    accessor: "VISITOR",
    width: 100,
    Cell: ({ row, value, fetchApi }: any) => {
      const { id: partnerId } = useParams();
      const memberId = row.original.memberId;
      const privileges = row.original.privileges;

      return (
        <Permissions
          code={PrivilegeCode.VISITOR}
          partnerId={partnerId}
          memberId={memberId}
          privileges={privileges}
          fetchApi={fetchApi}
        />
      );
    },
  },
  {
    Header: "NOTICE",
    accessor: "NOTICE",
    width: 100,
    Cell: ({ row, value, fetchApi }: any) => {
      const { id: partnerId } = useParams();
      const memberId = row.original.memberId;
      const privileges = row.original.privileges;

      return (
        <Permissions
          code={PrivilegeCode.NOTICE}
          partnerId={partnerId}
          memberId={memberId}
          privileges={privileges}
          fetchApi={fetchApi}
        />
      );
    },
  },
  {
    Header: "삭제",
    accessor: "Delete",
    width: 100,
    Cell: ({ row, value, fetchApi }: any) => {
      const { id: partnerId } = useParams();
      const memberId = row.original.memberId;
      const { executeAsync: deletePartnerMember } = useApiOperation(deletePartnerMemberAsync);
      const { setLoadingBar } = useLoadingBarContext();
      return (
        <button
          type={"button"}
          className="base-trash-btn"
          onClick={async () => {
            if (partnerId) {
              setLoadingBar(true);
              const { status } = await deletePartnerMember({ memberId, partnerId });
              if (status >= 200 && status < 300) {
                setTimeout(() => {
                  fetchApi(partnerId);
                  setLoadingBar(false);
                }, 1500);
              }
            }
          }}
        ></button>
      );
    },
  },
];

export default columns;
